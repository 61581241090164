import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import axios from 'axios';
import Collapsible from './Expand';
import { useAuth0 } from '@auth0/auth0-react';

const StyledDiv = styled.div`
  background-color: #fff;
  box-shadow: 0 0 10px gray;
  z-index: 1;
  min-height: 38rem;
  margin: 2rem 2rem;
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  min-width: 60rem;
  border-radius: 0.5rem;
  padding: 1rem;
`;

const StyledTop = styled.div`
  padding: 0rem 1rem 2rem 1rem;
  display: flex;
`;

const Title = styled.span`
  font-size: 26px;
  font-weight: 600;
  color: #606060;
  background-color: #f7f8f8;
  flex: 1;
  text-align: center;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 40rem;
  height: auto;
  background: #f7f7f7;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
  overflow: hidden;
`;

const ItemContent = styled.div`
  flex: 3;
  flex-direction: column;
  padding: 1rem;
`;

const ItemText = styled.div`
  color: ${({ isActive }) => (isActive ? 'green' : 'black')};
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-family: 'Poppins', sans-serif;
  padding: 0.25rem 0.5rem; /* Padding to add some space around the text */
  border-radius: 4px; /* Rounded corners */
`;

const PIIText = styled.div`
  color: ${({ isActive }) => (isActive ? 'green' : 'black')};
  font-size: 1.1rem;
  margin-left: 90rem;
  font-weight: 730;
  font-family: 'Poppins', sans-serif;
  
  border-radius: 4px; /* Rounded corners */
`;
const ItemText1 = styled.div`
  color: ${({ isActive }) => (isActive ? 'green' : 'black')};
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
  font-family: 'Poppins', sans-serif;
`;

const StatusIndicatorButton = styled.div`
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  display: flex;
  align-items: center;
  color: ${({ status }) => (status ? '#05ca23 ' : '#8B0000')};
`;

const StatIndicator = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${({ status }) => (status ? '#2F2C2C' : '#333')};
  border: 2px solid ${({ status }) => (status ? '#FF8C00' : '#222')};
  box-shadow: 0 0 5px ${({ status }) => (status ? '#2F2C2C' : '#333')};
  margin-right: 0.75rem;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
    background-color: ${({ status }) => (status ? '#FFB347' : '#444')};
  }
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
`;
const CollapsibleHeader = styled.div`
  background-color: #f4f4f4; /* Light background color for the header */
  padding: 2px; /* Add some padding around the text */
  border-bottom: 1px solid #ddd; /* A border line below the header */
  font-weight: bold; /* Make the text bold */
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  background-color: ${({ type }) => (type === 'give' ? '#4CAF50' : '#FF6347')};
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const MAPPING = {
  'Consent0':'Device identification through characteristic scanning',
  'Consent1':'Storage and access of information on your device',
  'Consent2':'Delivery of personalized ads and content'

};
const FIDMAPPING = {
  'treasurepack':'Travellogues Ltd',
  'aqilliz':'News Agency Ltd',
  'fid1':'Fiduciary 1'

};
const Dashboard = () => {
 
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  console.log('auth in dashboard?',localStorage.getItem('authenticated'))
  
  const [viewBrands, setViewBrands] = useState([]);
  const [selectedConsents, setSelectedConsents] = useState({});
  const [loading, setLoading] = useState(true);
  const {
    user,
  } = useAuth0();
  // const fid = 'fid1' //logic to implement dynamic fid
  // var fid = localStorage.getItem('fiduciaryId');
  // console.log('Fiduciary Id is:', fid); 
  useEffect(() => {
    if (!viewBrands || viewBrands.length === 0) getBrands();
  }, [user]);
  if (!localStorage.getItem('authenticated') && user === undefined){
    localStorage.setItem('authenticated',true)
    loginWithRedirect();
  }
  useEffect(() => {
    const initialConsents = {};
    viewBrands.forEach(item => {
      if (item.revokable) {
        initialConsents[item.fiduciaryId] = item.consents.split(',').map(consent => consent.trim());
      }
    });
    setSelectedConsents(initialConsents);
  }, [viewBrands]);

  const getBrands = () => {
    axios.get(`https://consent-mgmt-svc.api.staging.aqilliz.com/consent-manager/principal/${user?.email}-principal@consentmanager.in/consent`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then(res => {
        setViewBrands(res.data);
        setLoading(false); // Stop loading after data is fetched
      })
      .catch(err => {
        console.error(err);
        setLoading(false); // Stop loading even on error
      });
  };


  const handleCheckboxChange = (fiduciaryId, consent, chk) => {
    console.log('check fid id', fiduciaryId, consent, chk)

    let currentConcents = { ...selectedConsents };
    console.log('currentConcents before', currentConcents);

    // Ensure the consent is trimmed and consistent
    const trimmedConsent = consent.trim();

    if (!currentConcents[fiduciaryId]) {
      console.log('coming null')
      currentConcents[fiduciaryId] = [];
    }

    console.log('checking if part', currentConcents[fiduciaryId].includes(trimmedConsent), currentConcents[fiduciaryId]);

    if (currentConcents[fiduciaryId].includes(trimmedConsent)) {
      currentConcents[fiduciaryId] = currentConcents[fiduciaryId].filter(c => c !== trimmedConsent);
      console.log('invoke inside', currentConcents);
    } else {
      console.log('coming in else part');
      currentConcents[fiduciaryId].push(trimmedConsent);
    }

    
    setSelectedConsents(currentConcents);
  };


  const handleGive =  (fiduciaryName,fiduciaryId,piiData) => {
    console.log('selectedConsents in handleGive', selectedConsents);
    const selected = selectedConsents[fiduciaryId];
    console.log(' in handleGive', fiduciaryId, selected);
    axios.post(`https://consent-mgmt-svc.api.staging.aqilliz.com/consent-manager/fiduciary/fd-${fiduciaryName}@consentmanager.in/principal/${user?.email}-principal@consentmanager.in/consent/update`, {
      piiData: piiData,
      consentTypes: selected,
    })
      .then(response => getBrands())
      .catch(error => console.error('Error:', error));
  };
  console.log("selected concents for now", selectedConsents);
  const handleRevoke = (fiduciaryId, piiData) => {
    console.log('handlerevoke,fiduciaryId ',fiduciaryId)
    axios.post(`https://consent-mgmt-svc.api.staging.aqilliz.com/consent-manager/fiduciary/fd-${fiduciaryId}@consentmanager.in/principal/${user?.email}-principal@consentmanager.in/consent/update`, {
      piiData: piiData,
      revoke: true,
    })
      .then(response => getBrands())
      .catch(error => console.error('Error:', error));
  };
  return (
    <StyledDiv>

      {loading ? (
        <div>Loading...</div> // Render loader while loading is true
      ) : (
        <>
          <StyledTop>
           {localStorage.getItem('authenticated') !==null && <Title>Manage Consent</Title>}
            
          </StyledTop>
          <PIIText><h4>Email Address: {user?.email}</h4></PIIText>
          {viewBrands.map((item, index) => (
            <div style={item.status.toLowerCase() === 'active' ? {} : { display: "none" }}>
              <Collapsible key={item.fiduciaryId} open={true}>
              <CollapsibleHeader>
                <h3>{FIDMAPPING[item.fiduciaryName]}</h3><br></br>
                
              </CollapsibleHeader>
              {/* <CollapsibleHeader> */}
                
              {/* </CollapsibleHeader> */}
                <ItemContainer>
                  <ItemContent>
                    {/* <ItemText>{`Site: ${item.fiduciaryName}`}</ItemText> */}
                    <ItemText1>{`Consents Given:`}</ItemText1>
                    <div style={{marginBottom:'10px'}}>
                      {Object.keys(MAPPING).map((consent, index) => (
                        <div key={index}>
                          <Checkbox
                            type="checkbox"
                            checked={selectedConsents[item.fiduciaryId]?.includes(consent.trim())}
                            disabled={!item.revokable}
                            onChange={(chk) => handleCheckboxChange(item.fiduciaryId, consent, chk)}
                          />
                          {MAPPING[consent.trim()]}
                        </div>
                      ))}
                    </div>
                    {/* <ItemText>{`Expiry Date: ${item.expiryDate}`}</ItemText> */}
                    {/* <StatusIndicatorButton status={item.status}>
                      <StatIndicator status={item.status} />
                      Status: {item.status ? 'Active' : 'Revoked'}
                    </StatusIndicatorButton> */}
                  </ItemContent>
                </ItemContainer>
                <ButtonContainer>
                  <Button type="give" onClick={() => handleGive(item.fiduciaryName,item.fiduciaryId, item.piiData)}>Save</Button>
                  <Button type="revoke" onClick={() => handleRevoke(item.fiduciaryName, item.piiData)}>Revoke</Button>
                </ButtonContainer>

              </Collapsible>

            </div>
          ))}
        </>
      )
      }
    </StyledDiv>
  );
};

export default Dashboard;
